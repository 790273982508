import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'
let urutan = 0
export default {
  data(){
    return {
      filteredData:[],
      search: '',
      loadingTb:false,
      isActive: [],
      options: {},
      optionsDJSDA: {},
      optionsDIPA: {},
      optionsKumulatif:{},
      optionsDIPA2:{},
      isLoaderProfile:false,
      profilLoan:{
        "setColor":"",
        "registerId": "-",
        "registerNm": "-",
        "lender": "-",
        "executingAgency": "-",
        "implementingAgency": "-",
        "tanggalEfektif": "-",
        "totalPinjaman": 0,
        "totalDipa": 0,
        "totalBulan": "-",
        "totalBulanTerpakai": "-",
        "totalBulanTerpakaiPrs": "-",
        "lokasi": "-",
        "statusKegiatan": "-",
        "pv": "-"
      },
      summary:[{
        "jumlahPinjaman" : 0,
        "realisasiKumulatif" : 0,
        "paguDipa" : 0,
        "realisasiDipa" : 0,
        "countAtRisk" : 0,
        "countBehindSchedule" : 0,
        "countOnSchedule" : 0,
        "prsDipa" : 0,
        "prsKumulatif" : 0
      }
        
      ],
      yearFilter:[],
      pieChartJumlah:[],
      pieChartJumlahDJSDA:[],
      pieChartJumlahDIPA:[], 
      pieChartJumlahDIPA2:[],      
      pieChartJumlahKumulatif:[], 
      pieChartTotal:[],
      tableSatker:[],
      tableDetailPaket:[],
      tbData:[],
			data_color :["#00C5DC","#F4516C","#FFB822","#8859E0","#0C5484","#66BB6A","#00838F","#e57373"],
      data_color_reverse :["#F4516C","#00C5DC"],
      seriesTotal: [],
      seriesTotalDIPA: [],
      optionsDJSDAbar: {},
      optionsDIPAbar: {},
      penyerapanTotal:[],
      seriesPenyerapan:[],
      penyerapanOption:{},
      penyerapanTotalAkumulasi:[],
      penyerapanOptionAkumulasi:{},
      istableDetail:false,
      titleDetail:null,
      tableDetailPaketAll:[],
      setUrut:0,
      listRegister:[],
      listPilihLoan:[]
     
    }
},
   created() {
    this.$emit(`update:layout`, LayoutAdmin);   
  },
  mounted(){


    this.getListTahun()
    this.$nextTick(() => {
      $('.selectpicker').selectpicker('render');
      this.cariData()
    })
  
    this.G_GetAny('kontraktual/per-satker/nested?tahun=2021').then(res => {
      this.tableSatker = res.data.content
    })

    this.G_GetRef('register','?isPln=true').then(data => {
      this.listRegister = data;
    });

   
    
  },
  components: {
      myTable
  },
  methods:{
    getListTahun() {
      for (let index = this.G_yearNow; index >= (this.G_yearNow-2); index--) {
        this.yearFilter.push(index);
      }
    },
    cariData(){
      this.getSummary('all',$('#tahun').val());
    },
    toggleSub(key){
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $('#sub_'+key).hide();
      }else{
        $('#sub_'+key).show();

      }

    },
    cariDataProfilLoan(){
      this.isLoaderProfile = true
      this.G_GetAny('/dashboard/profil/loan/'+this.$refs.register.getValue()).then(res => {

        if (res.status == 500) {
            this.isLoaderProfile = false

            this.$refs.notif.warning(res.data.message);
        }else{
            this.profilLoan = res.data

            if (res.data.color == 'red') {
              this.profilLoan.setColor = 'danger'
            }
            else if(res.data.color == 'orange'){
              this.profilLoan.setColor = 'warning'
            }
            else{
              this.profilLoan.setColor = 'success'

            }

            console.table(this.profilLoan)

            this.pieChartJumlahDIPA2 = res.data.realisasiDipa
            this.optionsDIPA2 = {
              legend : {
                show:true,
                position:"bottom"
              },
              colors:  this.data_color,
              dataLabels: {
                enabled: true,
              },
              labels: this.pieChartJumlahDIPA2.label,
            
              plotOptions: {
                pie: {
                  customScale: 1,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        show:true,
      
                      },
                      value: {
                        formatter: function (value) {
                          let val = (value / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:true,
                      },
                      total: {
                        formatter: function (w) {
                          let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                          let val = (setTotal / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:false,
                      }
      
                    }
                  }
                }
              }
            }
    
            this.pieChartJumlahKumulatif = res.data.realisasiKumulatif
            this.optionsKumulatif = {
              legend : {
                show:true,
                position:"bottom"
              },
              colors:  this.data_color,
              dataLabels: {
                enabled: true,
              },
              labels: this.pieChartJumlahKumulatif.label,
            
              plotOptions: {
                pie: {
                  customScale: 1,
                  donut: {
                    labels: {
                      show: true,
                      
                      name: {
                        show:true,
      
                      },
                      value: {
                        formatter: function (value) {
                          let val = (value / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:true,
                      },
                      total: {
                        formatter: function (w) {
                          let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                          let val = (setTotal / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:false,
                      }
      
                    }
                  }
                }
              }
            }
    
            this.isLoaderProfile = false
        }
       
       

      })

      // this.filterData2(this.$refs.register.getValue())
      this.filterData(this.$refs.register.getValue())
    },
    getSummary(type,tahun){
      let filter = '';
      if(tahun){
        filter += '?tahun='+tahun
      }else{
        filter += '?tahun=2021'
      }

      if (type) {
        if (type != 'all') {
          filter += '&sumberDana='+type
        }
      }

      this.G_GetAny('dashboard/summary'+filter).then(res => {
        this.summary = res.data
      })

      this.G_GetAny('kontraktual/summary/byStatus/count/pie'+filter).then(res => {
        this.pieChartJumlah = res.data
        this.options = {
          legend : {
            show:true,
          },
          colors:  this.data_color,
          dataLabels: {
            enabled: true,
          },
          labels: this.pieChartJumlah.label,
         
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show:true,
  
                  },
                  value: {
                    show:true,
                  },
                  total: {
                    show:true,
                  }
  
                }
              }
            }
          }
        }
      })

      this.G_GetAny('/dashboard/pelaksanaan-pinjaman/pie'+filter).then(res => {
        
        let revertData = res.data.data.reverse()
        let revertLabel = res.data.label.reverse()

        this.pieChartJumlahDJSDA = revertData

        this.optionsDJSDA = {
          height:100,
          legend : {
            show:true,
          },
          colors:  this.data_color_reverse,
          dataLabels: {
            enabled: true,
          },
          labels: revertLabel,
         
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show:true,
  
                  },
                  value: {
                    formatter: function (value) {
                      let val = (value / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  },
                  total: {
                    formatter: function (w) {
                      let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                      let val = (setTotal / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  }
  
                }
              }
            }
          }
        }
      })

      this.G_GetAny('dashboard/penyerapan-dipa/pie'+filter).then(res => {

        let revertData = res.data.data.reverse()
        let revertLabel = res.data.label.reverse()

        this.pieChartJumlahDIPA = revertData
        this.optionsDIPA = {
          legend : {
            show:true,
          },
          colors:  this.data_color_reverse,
          dataLabels: {
            enabled: true,
          },
          labels: revertLabel,
         
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show:true,
  
                  },
                  value: {
                    formatter: function (value) {
                      let val = (value / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  },
                  total: {
                    formatter: function (w) {
                      let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                      let val = (setTotal / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  }
  
                }
              }
            }
          }
        }
      })

      this.G_GetAny('dashboard/pelaksanaan-pinjaman/bar'+filter).then(res => {
     
        let setSeries = res.data.series 
        let setLabel = res.data.label
        let tmpSeries = []
        let tmpDataPinjaman = []

        setSeries[0].data.forEach((vData,kData) => {
          tmpDataPinjaman.push(
            {
              x: setLabel[kData],
              y: setSeries[1].data[kData],
              goals: [
                {
                  name: setSeries[0].name,
                  value: vData,
                    strokeColor: '#039be57d',
                    strokeWidth: 8
                }
              ]
            }
          )
        });

        tmpSeries.push(
          {
            name: setSeries[1].name,
            data: tmpDataPinjaman
          }
        )

        this.seriesTotal = tmpSeries
        this.optionsDJSDAbar = {
          chart: {
            height: 500,
            type: 'bar'
          },
          colors: ["#F4516C"],
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: [setSeries[1].name,setSeries[0].name],
            markers: {
              fillColors: ['#F4516C', '#039be57d']
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function(val, opt) {
              const goals =
                opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                  .goals
              let persentase = (val) ? (val /goals[0].value)*100 : 0

              if (goals && goals.length) {
                return `${persentase.toFixed(2)}%`
              }
              return val
            }
          },
          
         
          yaxis: [{
            labels: {
              formatter: function (value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return "Rp "+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  
                // return "Rp "+ G_numFormat(value);
              }
            },
          
          }]
          
        }

     

      })

      this.G_GetAny('dashboard/penyerapan-dipa/bar'+filter).then(res => {
     
        let setSeries = res.data.series 
        let setLabel = res.data.label
        let tmpSeries = []
        let tmpDataPinjaman = []

        setSeries[0].data.forEach((vData,kData) => {
          tmpDataPinjaman.push(
            {
              x: setLabel[kData],
              y: setSeries[1].data[kData],
              goals: [
                {
                  name: setSeries[0].name,
                  value: vData,
                    strokeColor: '#039be57d',
                    strokeWidth: 8
                }
              ]
            }
          )
        });

        tmpSeries.push(
          {
            name: setSeries[1].name,
            data: tmpDataPinjaman
          }
        )
     
        this.seriesTotalDIPA = tmpSeries

        this.optionsDIPAbar = {
          chart: {
            height: 500,
            type: 'bar'
          },
          colors: ["#F4516C"],
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: [setSeries[1].name,setSeries[0].name],
            markers: {
              fillColors: ['#F4516C', '#039be57d']
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function(val, opt) {
              const goals =
                opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                  .goals
              let persentase = (val) ? (val /goals[0].value)*100 : 0

              if (goals && goals.length) {
                return `${persentase.toFixed(2)}%`
              }
              return val
            }
          },
          
         
          yaxis: [{
            labels: {
              formatter: function (value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return "Rp "+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  
                // return "Rp "+ G_numFormat(value);
              }
            },
          
          }]
          
        }
      })

      this.G_GetAny('kontraktual/per-satker/nested'+filter).then(res => {
        this.tableSatker = res.data.content
      })

    

      this.G_GetAny('dashboard/penyerapan/bulanan?tahun=2021&akumulasi=true').then(res => {
        

        let tmpList = []
        let arrSub = {};

        res.data.series.forEach((v,idx) => {
          arrSub = {};
          arrSub["text"] = v.name;
          arrSub["value"] = idx;
  
          tmpList.push(arrSub);

        });

        this.listPilihLoan = tmpList

      })

    },
    // filterData2(filter){
    //   this.G_GetAny('dashboard/penyerapan/bulanan?tahun=2021&akumulasi=false').then(res => {
        
    //     let setSeries = res.data.series
    //     let filtered = setSeries.filter(setSeries => setSeries.name.includes(filter));

    //     this.filteredData.push(filtered)
    //     this.penyerapanTotalAkumulasi = this.filteredData
    //     this.penyerapanOptionAkumulasi = {
    //       chart: {
    //         height: 500,
    //         type: 'line',
    //         zoom: {
    //           enabled: false
    //         }
    //       },
    //       legend : {
    //         show:true,
    //         position:"right"
    //       },
    //       dataLabels: {
    //         enabled: false
    //       },
    //       stroke: {
    //         curve: 'smooth'
    //       },
         
    //       grid: {
    //         row: {
    //           colors: ['#f3f3f3', 'transparent'], 
    //           opacity: 0.5
    //         },
    //       },
    //       xaxis: {
    //         categories: res.data.label
    //       },
    //       yaxis: {
    //         labels: {
    //           formatter: function (value) {
    //             let val = (value / 1).toFixed(0).replace('.', ',')
    //             return "Rp "+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  
    //             // return "Rp "+ G_numFormat(value);
    //           }
    //         },
    //       },
    //     }
    //   })
    // },
    filterData(filter){
    
      this.G_GetAny('dashboard/penyerapan/bulanan?tahun=2021&akumulasi=true').then(res => {

        this.G_GetAny('dashboard/penyerapan/bulanan?tahun=2021&akumulasi=false').then(resFalse => {

            let setSeries = res.data.series
            let setSeriesFalse = resFalse.data.series

            let filtered = setSeries.filter(setSeries => setSeries.name.includes(filter));
            let filteredFalse = setSeriesFalse.filter(setSeriesFalse => setSeriesFalse.name.includes(filter));
            let tmpData = []

            filtered[0].name = 'Akumulasi Penyerapan Bulanan'
            filteredFalse[0].name = 'Penyerapan Bulanan'

            filtered[0].type = 'line'
            filteredFalse[0].type = 'bar'

            

            tmpData.push(filteredFalse[0])
            tmpData.push(filtered[0])
           

            this.seriesPenyerapan = tmpData
            
    
            // this.seriesPenyerapan = newSeries
            this.penyerapanOption = {
              chart: {
                height: 500,
                type: 'line',
                zoom: {
                  enabled: false
                }
              },
              legend : {
                show:true,
                position:"bottom"
              },
              stroke: {
                show: true,
                curve: 'smooth',
                width: 2,
                dashArray: 0,      
            },
              dataLabels: {
                enabled: false
              },
              labels: res.data.label,
              
            
              grid: {
                row: {
                  colors: ['#f3f3f3', 'transparent'], 
                  opacity: 0.5
                },
              },
              
              yaxis: {
                labels: {
                  formatter: function (value) {
                    let val = (value / 1).toFixed(0).replace('.', ',')
                    return "Rp "+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      
                    // return "Rp "+ G_numFormat(value);
                  }
                },
              },
            }
        })
    

      })
    },
    selectAll(type){
      if (type === 'akumulasi_loan') {
        this.$refs.akumulasi_loan.selectAll()
      }else if(type === 'akumulasi_loan2'){
        this.$refs.akumulasi_loan2.selectAll()
      }
        
      
    },
    removeAll(type){
      if (type === 'akumulasi_loan') {
        this.$refs.akumulasi_loan.removeAll()
      }else if(type === 'akumulasi_loan2'){
        this.$refs.akumulasi_loan2.removeAll()
      }
    },
    detailPaketStatus(status){

      this.istableDetail = true

      urutan = 0

      let sumberDana = $('#sumberDana').val()
      if (sumberDana == 'all' || sumberDana == '' || sumberDana == null) {
        sumberDana = '';
      }else{
        sumberDana = '&sumberDana='+sumberDana;
      }
      
      this.G_GetAny('kontraktual/per-paket/nested?status='+status+'&tahun=2021'+sumberDana).then(res => {
        this.tableDetailPaket = res.data.content
        this.istableDetail = false
        this.$refs.modalPaket.open("Detail Paket "+status,1200)

        this.titleDetail = "Detail Paket "+status
      })

    },
    detailPaketAll(){

      let sumberDana = $('#sumberDana').val()
      if (sumberDana == 'all' || sumberDana == '' || sumberDana == null) {
        sumberDana = '';
      }else{
        sumberDana = '&sumberDana='+sumberDana;
      }

      urutan = 0
      this.G_GetAny('kontraktual/per-paket/nested?tahun=2021'+sumberDana).then(res => {
        
        this.tableDetailPaketAll = res.data.content

        this.$refs.modalAll.open("DETAIL SEMUA PAKET ",'90%')

        this.titleDetail = "DETAIL SEMUA PAKET "
      })
    },
    detailPaket(kodeSatker){

      let sumberDana = $('#sumberDana').val()
      if (sumberDana == 'all' || sumberDana == '' || sumberDana == null) {
        sumberDana = '';
      }else{
        sumberDana = '&sumberDana='+sumberDana;
      }
      
      urutan = 0
      
      this.G_GetAny('kontraktual/per-paket/nested?kdsatker='+kodeSatker+'&tahun=2021'+sumberDana).then(res => {
        this.tableDetailPaket = res.data.content[0].childs[0]

        this.$refs.modal.open("DETAIL PAKET "+this.tableDetailPaket.nmsatker,1200)
      })


     
     
    },
    setNoUrut(idx){
      urutan++ 
      return urutan
    },
  }
}